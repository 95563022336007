import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

function Privacy() {
  return (
    <Layout>
      <SEO title="IUS Terms" />
      <div className="section Dark">
        <div className="container">
          <div className="card">
            <div className="card-content">
              <p>
                This web page represents a legal document that serves as our
                Terms of Use and it governs the legal terms of our website,
                sub-domains, and any associated web-based and mobile
                applications (collectively, "Website"), as owned and operated by
                Francisco Javier Vera Gomez. As well as any mobile app
                developed, owned and/or operated by Francisco Javier Vera Gomez
              </p>{" "}
              <br />
              <p>
                Capitalized terms, unless otherwise defined, have the meaning
                specified within the Definitions section below. This Terms of
                Use, along with our <a href="/ius/privacy">Privacy Policy</a>,
                any mobile license agreement, and other posted guidelines within
                our Website, collectively "Legal Terms", constitute the entire
                and only agreement between you and Francisco Javier Vera Gomez,
                and supersede all other agreements, representations, warranties
                and understandings with respect to our Website or mobile app and
                the subject matter contained herein. We may amend our Legal
                Terms at any time without specific notice to you. The latest
                copies of our Legal Terms will be posted on our Website, and you
                should review all Legal Terms prior to using our Website. After
                any revisions to our Legal Terms are posted, you agree to be
                bound to any such changes to them. Therefore, it is important
                for you to periodically review our Legal Terms to make sure you
                still agree to them.
              </p>
              <br />
              <p>
                By using our Website or mobile app, you agree to fully comply
                with and be bound by our Legal Terms. Please review them
                carefully. If you do not accept our Legal Terms, do not access
                and use our Website. If you have already accessed our Website
                and do not accept our Legal Terms, you should immediately
                discontinue use of our Website.
              </p>{" "}
              <br />
              <p>
                The last update to our Terms of Use was posted on 28 January
                2019.
              </p>
              <br />
              <p>
                <strong>Definitions</strong>
                <br /> <br />
                The terms "us" or "we" or "our" refers to Francisco Javier Vera
                Gomez, the owner of the Website.
              </p>
              <p>
                A "Visitor" is someone who merely browses our Website or mobile
                apps, but has not registered as Member.
              </p>
              <p>
                A "Member" is an individual that has registered with us to use
                our Service.
              </p>
              <p>
                Our "Service" represents the collective functionality and
                features as offered through our Website or mobile apps to our
                Members.
              </p>
              <p>
                A "User" is a collective identifier that refers to either a
                Visitor, a Member or a Person who has installed one or more of
                our mobile apps.
              </p>
              <p>
                All text, information, graphics, audio, video, and data offered
                through our Website or mobile apps are collectively known as our
                "Content".
              </p>{" "}
              <br />
              <p>
                <strong>Legal Compliance</strong>
                <br />
                You agree to comply with all applicable domestic and
                international laws, statutes, ordinances, and regulations
                regarding your use of our Website. Francisco Javier Vera Gomez
                reserves the right to investigate complaints or reported
                violations of our Legal Terms and to take any action we deem
                appropriate, including but not limited to canceling your Member
                account, reporting any suspected unlawful activity to law
                enforcement officials, regulators, or other third parties and
                disclosing any information necessary or appropriate to such
                persons or entities relating to your profile, email addresses,
                usage history, posted materials, IP addresses and traffic
                information, as allowed under our Privacy Policy.
              </p>
              <br />
              <p>
                <strong>Intellectual Property</strong>
                <br />
                Our Website or mobile apps may contain our service marks or
                trademarks as well as those of our affiliates or other
                companies, in the form of words, graphics, and logos. Your use
                of our Website or mobile apps does not constitute any right or
                license for you to use such service marks/trademarks, without
                the prior written permission of the corresponding service
                mark/trademark owner. Our Website and mobile apps are also
                protected under international copyright laws. The copying,
                redistribution, use or publication by you of any portion of our
                Website or mobile apps is strictly prohibited. Your use of our
                Website or mobile apps does not grant you ownership rights of
                any kind in our Website or mobile apps.
              </p>
              <br />
              <p>
                <strong>Links to Other Websites</strong>
                <br />
                Our Website or mobile apps may contain links to third party
                websites. These links are provided solely as a convenience to
                you. By linking to these websites, we do not create or have an
                affiliation with, or sponsor such third party websites. The
                inclusion of links within our Website does not constitute any
                endorsement, guarantee, warranty, or recommendation of such
                third party websites. Francisco Javier Vera Gomez has no control
                over the legal documents and privacy practices of third party
                websites; as such, you access any such third party websites at
                your own risk.
              </p>
              <br />
              <p>
                <strong>General Terms</strong>
                <br />
                Our Legal Terms shall be treated as though it were executed and
                performed in Puebla, Mexico and shall be governed by and
                construed in accordance with the laws of Puebla, Mexico without
                regard to conflict of law principles. In addition, you agree to
                submit to the personal jurisdiction and venue of such courts.
                Any cause of action by you with respect to our Website, must be
                instituted within one (1) year after the cause of action arose
                or be forever waived and barred. Should any part of our Legal
                Terms be held invalid or unenforceable, that portion shall be
                construed consistent with applicable law and the remaining
                portions shall remain in full force and effect. To the extent
                that any Content in our Website conflicts or is inconsistent
                with our Legal Terms, our Legal Terms shall take precedence. Our
                failure to enforce any provision of our Legal Terms shall not be
                deemed a waiver of such provision nor of the right to enforce
                such provision. The rights of Francisco Javier Vera Gomez under
                our Legal Terms shall survive the termination of our Legal
                Terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Privacy
